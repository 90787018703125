import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/Software/Other_Platforms/NavButtons';
import ForumBox from 'components/Software/Other_Platforms/QNAP/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "QNAP QVR Pro 2.1 Surveillance Software",
  "path": "/Software/Other_Platforms/QNAP_QVR_Pro/",
  "dateChanged": "2021-11-26",
  "author": "Mike Polinowski",
  "excerpt": "All INSTAR IP cameras are supported by the QNAP QVR Pro 2.1. Manage all your cameras and video recordings.",
  "image": "./P_SearchThumb_QNAP.png",
  "social": "/images/Search/P_SearchThumb_QNAP.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Other_QNAP_white.webp",
  "chapter": "Software"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const EuiSpacer = makeShortcode("EuiSpacer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='QNAP QVR Pro 2.1 Surveillance Software' dateChanged='2021-11-26' author='Mike Polinowski' tag='INSTAR IP Camera' description='All INSTAR IP cameras are supported by the QNAP QVR Pro 2.1. Manage all your cameras and video recordings.' image='/images/Search/P_SearchThumb_QNAP.png' twitter='/images/Search/P_SearchThumb_QNAP.webp' location={props.location.pathname} mdxType="SEOHelmet" />
 
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Andere_Plattform/QNAP_QVR_Pro/' locationFR='/fr/Software/Other_Platforms/QNAP_QVR_Pro/' crumbLabel="QNAP" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#qvr-pro-21"
        }}>{`QVR Pro 2.1`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#adding-a-camera"
            }}>{`Adding a Camera`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#onvif"
                }}>{`ONVIF`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#instar-preset"
                }}>{`Instar Preset`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#generic-rtsp"
                }}>{`Generic RTSP`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#activating-the-video-recordings"
            }}>{`Activating the Video Recordings`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#motion-detection"
            }}>{`Motion Detection`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#detection-settings"
                }}>{`Detection Settings`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#rules"
                }}>{`Rules`}</a></li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <h2 {...{
      "id": "qvr-pro-21",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#qvr-pro-21",
        "aria-label": "qvr pro 21 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`QVR Pro 2.1`}</h2>
    <h3 {...{
      "id": "adding-a-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#adding-a-camera",
        "aria-label": "adding a camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adding a Camera`}</h3>
    <p><a parentName="p" {...{
        "href": "https://www.qnapsecurity.com/feature/qvr/qvr-pro-official/"
      }}>{`QVR Pro`}</a>{` is built-in in the QVR Pro appliance provides embedded monitoring channels, allowing you to quickly build a home surveillance environment.`}</p>
    <p>{`Open the QVR Pro application from your QNAP dashboard, select `}<strong parentName="p">{`Camera Settings`}</strong>{` and click on the Plus icon to add a new camera:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9234fdd76598b60f3ef0546807c7d1c4/44a54/QVRPro2_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.347826086956516%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABMUlEQVQoz52PUUoDMRCG90j60G2L7UK1tCD43DOodyh4Eit4I9EHRREU2bZJdjMzSZPZsCu7tlWwCPVjCP8/4XuY6Gw0mAwHp6Nx0u1cnl/Mrm9uZ7Or6TQ+POh32r047rXj+o3jo1Yr6XZP+skwSUaD4/54Ej3c3b8+Pr29PC8/3h1BWNmwsmwQlcRMUibr8GNISZQShNCLeaQyrQGlUoBIxgAgIH5lMgaJ6kDrQSIN4JnLqiqrKhLL5WJek6bpoiHPMyJCBMIaACAiu8EY453jhijLMiFEo8+llACglAKgXOtcg9Z11YBcfMMbIkQMIWy3RVFsa2AOZRmazrtYyzt+ioKF8EJ4Zu/9njI3Upoaa93myH1kZsestf6n7J3z3jvnil1n/yVbawFAN1hrf8ufKZpS8ls6XcYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9234fdd76598b60f3ef0546807c7d1c4/e4706/QVRPro2_01.avif 230w", "/en/static/9234fdd76598b60f3ef0546807c7d1c4/d1af7/QVRPro2_01.avif 460w", "/en/static/9234fdd76598b60f3ef0546807c7d1c4/7f308/QVRPro2_01.avif 920w", "/en/static/9234fdd76598b60f3ef0546807c7d1c4/33c9a/QVRPro2_01.avif 1017w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9234fdd76598b60f3ef0546807c7d1c4/a0b58/QVRPro2_01.webp 230w", "/en/static/9234fdd76598b60f3ef0546807c7d1c4/bc10c/QVRPro2_01.webp 460w", "/en/static/9234fdd76598b60f3ef0546807c7d1c4/966d8/QVRPro2_01.webp 920w", "/en/static/9234fdd76598b60f3ef0546807c7d1c4/11ae3/QVRPro2_01.webp 1017w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9234fdd76598b60f3ef0546807c7d1c4/81c8e/QVRPro2_01.png 230w", "/en/static/9234fdd76598b60f3ef0546807c7d1c4/08a84/QVRPro2_01.png 460w", "/en/static/9234fdd76598b60f3ef0546807c7d1c4/c0255/QVRPro2_01.png 920w", "/en/static/9234fdd76598b60f3ef0546807c7d1c4/44a54/QVRPro2_01.png 1017w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9234fdd76598b60f3ef0546807c7d1c4/c0255/QVRPro2_01.png",
              "alt": "QNAP QVR Pro 2.1 Surveillance Software",
              "title": "QNAP QVR Pro 2.1 Surveillance Software",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    {/* All INSTAR cameras [are ONVIF compatible](/Quick_Installation/ONVIF/) and the automatic search should be able to find every ONVIF camera on your local network (the [ONVIF Service](/Web_User_Interface/1080p_Series/Network/ONVIF/) has to be active): */}
    {/* /de/Schnell_Installation/ONVIF/ */}
    {/* /de/Web_User_Interface/1080p_Serie/Netzwerk/ONVIF/ */}
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4df44d536614e072bcf67fe23759da5f/51800/QVRPro2_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "47.82608695652174%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABf0lEQVQoz33NW2+bMBTAcb7/19nrVClKE26hXSmruk5KAQcSTBoMvnB8wZ6gzR62qj/9dXR8Xuzt93uMsZQKPieXxv8JIbzT8YhxQ0j3Tz0hfd93XdddLnQYBOecM86YEGKenEspvafnlxJVrzl6zcu8QCWq8uJQlIe8QMvzsM/LElX1ER9P+NS09QnXNc4LNFDq3fpJuHsMk4/86GG9TdbbJNilYZJtgqdg9xju0iBOg/hhE9xtwx9BnG6C+wafvb4nUoJWak4rQghuGs6Y0UqCOp8VjPNdGw0wXt7ehODGaK2VUtJr25ZxLkYpRhAjNLhFh6ppMBeCcThUQOkIMAIApbSu62EYAKQQ88Uzxlg7OfeedUY7raxWbj7aSVlr3TtrrTFmmia7UEp5NW5/X+hzS15a8rMdfg2qFgpRQEyWTDYcOPtAF/Jq/tkqDdZJNyec40vsOsF9xfsWJ9/T9CbLbrIsSpL7OLyLo78lcRRfRdG8+2G02vqr1cpf3/4B/8UolvihqP8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4df44d536614e072bcf67fe23759da5f/e4706/QVRPro2_02.avif 230w", "/en/static/4df44d536614e072bcf67fe23759da5f/d1af7/QVRPro2_02.avif 460w", "/en/static/4df44d536614e072bcf67fe23759da5f/7f308/QVRPro2_02.avif 920w", "/en/static/4df44d536614e072bcf67fe23759da5f/456c2/QVRPro2_02.avif 1196w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4df44d536614e072bcf67fe23759da5f/a0b58/QVRPro2_02.webp 230w", "/en/static/4df44d536614e072bcf67fe23759da5f/bc10c/QVRPro2_02.webp 460w", "/en/static/4df44d536614e072bcf67fe23759da5f/966d8/QVRPro2_02.webp 920w", "/en/static/4df44d536614e072bcf67fe23759da5f/31d32/QVRPro2_02.webp 1196w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4df44d536614e072bcf67fe23759da5f/81c8e/QVRPro2_02.png 230w", "/en/static/4df44d536614e072bcf67fe23759da5f/08a84/QVRPro2_02.png 460w", "/en/static/4df44d536614e072bcf67fe23759da5f/c0255/QVRPro2_02.png 920w", "/en/static/4df44d536614e072bcf67fe23759da5f/51800/QVRPro2_02.png 1196w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4df44d536614e072bcf67fe23759da5f/c0255/QVRPro2_02.png",
              "alt": "QNAP QVR Pro 2.1 Surveillance Software",
              "title": "QNAP QVR Pro 2.1 Surveillance Software",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You now have 3 choices:`}</p>
    <ol>
      <li parentName="ol">{`Add your Camera as an generic ONVIF camera by simply selecting it and clicking on `}<strong parentName="li">{`Next`}</strong>{`.`}</li>
      <li parentName="ol">{`Click `}<strong parentName="li">{`Next`}</strong>{` and add your camera manually via the INSTAR profile from the following page.`}</li>
      <li parentName="ol">{`Click `}<strong parentName="li">{`Next`}</strong>{` and add your camera as a generic RTSP camera.`}</li>
    </ol>
    <h4 {...{
      "id": "onvif",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#onvif",
        "aria-label": "onvif permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ONVIF`}</h4>
    <p>{`If you choose to add your camera via it's ONVIF service you don´t have to change anything. Just click on `}<strong parentName="p">{`Test`}</strong>{` to verify that everything is set up correctly. And click `}<strong parentName="p">{`Next`}</strong>{` to continue:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0cc8132ff43b82305adf78fbab0090f8/e72de/QVRPro2_03a.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.043478260869556%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABbElEQVQoz6WQy07CQBRA5wd1DQlQApKw8guILlz4R2hAaKGoLcUAalhAW3wESkFiAp1305qZQjS6MNGTk5t753HnAY7z2UI+X8hlTysn52dnp5VKJpXKpqWpnbl0uqgoR7lssVhWCuVSXlEymdLBIbBtd+n7lBAEYSLGmOzF+4gRkhESBDHCECK62YCF583ns/V6HQQBIXLVJ1BWUCRyAkKIki4YUcbAeOJMbNdxn15eZ3PPT/QWy4W/8hbLb4pBb2k7U3f6DBEC1Vqr0bptdSxN715pN5puNjVDbZtq22hf91odS9VNVTfbHUu/uWtot9V6s1rTLuu6v3oDUD4yDHnIGaOUc0ZFFEUoYZIkF19DtpwzzjmlFMR/hXP+v83bIIAIBRBuAsE7ZlReS/gDzsSFEyghICIkCnkchjHnolsUR7+dGUniOAYXD4+N8URz3J22o0qbjmuORveDwfALRn9oWFa3axqG0e/2PgBcckYbSyMuBAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0cc8132ff43b82305adf78fbab0090f8/e4706/QVRPro2_03a.avif 230w", "/en/static/0cc8132ff43b82305adf78fbab0090f8/d1af7/QVRPro2_03a.avif 460w", "/en/static/0cc8132ff43b82305adf78fbab0090f8/7f308/QVRPro2_03a.avif 920w", "/en/static/0cc8132ff43b82305adf78fbab0090f8/b84c5/QVRPro2_03a.avif 1198w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0cc8132ff43b82305adf78fbab0090f8/a0b58/QVRPro2_03a.webp 230w", "/en/static/0cc8132ff43b82305adf78fbab0090f8/bc10c/QVRPro2_03a.webp 460w", "/en/static/0cc8132ff43b82305adf78fbab0090f8/966d8/QVRPro2_03a.webp 920w", "/en/static/0cc8132ff43b82305adf78fbab0090f8/40d0b/QVRPro2_03a.webp 1198w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0cc8132ff43b82305adf78fbab0090f8/81c8e/QVRPro2_03a.png 230w", "/en/static/0cc8132ff43b82305adf78fbab0090f8/08a84/QVRPro2_03a.png 460w", "/en/static/0cc8132ff43b82305adf78fbab0090f8/c0255/QVRPro2_03a.png 920w", "/en/static/0cc8132ff43b82305adf78fbab0090f8/e72de/QVRPro2_03a.png 1198w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0cc8132ff43b82305adf78fbab0090f8/c0255/QVRPro2_03a.png",
              "alt": "QNAP QVR Pro 2.1 Surveillance Software",
              "title": "QNAP QVR Pro 2.1 Surveillance Software",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Note`}</strong>{` that all INSTAR HD and Full HD camera models support the `}<a parentName="p" {...{
          "href": "/en/Quick_Installation/ONVIF/"
        }}>{`ONVIF S Profile`}</a>{`. Only the 2k+ WQHD models provide support for the newer `}<strong parentName="p">{`T Profile`}</strong>{`. The main difference here is, that the latter allows you to use the camera's PIR sensor as an alarm trigger for the QVR Motion Detection.`}</p>
    </blockquote>
    <h4 {...{
      "id": "instar-preset",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#instar-preset",
        "aria-label": "instar preset permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`INSTAR Preset`}</h4>
    <p>{`By stopping the ONVIF network and clicking on `}<strong parentName="p">{`Next`}</strong>{` you now have the option to add your camera manually:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9e11eb0c071d901b027f02b86d85eed3/95e27/QVRPro2_03b.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "47.82608695652174%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABW0lEQVQoz5WP207CQBCG9/0fwcQ77zwkEECg5VzQiBhM5GCUHmjZ3bItRNruodCuafGCxAvxy5fJPzM3M8AwDEII54Ixzs6CU0rFfo91A0C4gnDle14YBpwzRimjNDoShnkJoig8DrMcBpRGXHBiWmChmwvdWuiW7UDTsk3LsZbOCmLsEuwShIm79o4B4TUmPp69f0zmpgPRpw4uLq/uCveN9kNN7ZarrUqtXVO79UavqnTVVr/e0Aolpap01KbW6j5W6p1CoXx9U7wt1qajMXAce7Px41iw/GnOGec8FlkrRBaCYMcoFUIc9ntKafi1jYId5QwbJpD/J01TKSVZ2iDNOV38SZIkUsq1ZQOIEHZdhPEKQojQ0vWJ73u5v9nk+p63CwKUnR3HMklkmmYmyeHss7drAkqjF+Vt0pzNf5zOGrnqbD4Yv46Gw+cTek9DbTDoa1p/0G+W778B8tIlPVDTcE8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9e11eb0c071d901b027f02b86d85eed3/e4706/QVRPro2_03b.avif 230w", "/en/static/9e11eb0c071d901b027f02b86d85eed3/d1af7/QVRPro2_03b.avif 460w", "/en/static/9e11eb0c071d901b027f02b86d85eed3/7f308/QVRPro2_03b.avif 920w", "/en/static/9e11eb0c071d901b027f02b86d85eed3/20787/QVRPro2_03b.avif 1199w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9e11eb0c071d901b027f02b86d85eed3/a0b58/QVRPro2_03b.webp 230w", "/en/static/9e11eb0c071d901b027f02b86d85eed3/bc10c/QVRPro2_03b.webp 460w", "/en/static/9e11eb0c071d901b027f02b86d85eed3/966d8/QVRPro2_03b.webp 920w", "/en/static/9e11eb0c071d901b027f02b86d85eed3/7a7d4/QVRPro2_03b.webp 1199w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9e11eb0c071d901b027f02b86d85eed3/81c8e/QVRPro2_03b.png 230w", "/en/static/9e11eb0c071d901b027f02b86d85eed3/08a84/QVRPro2_03b.png 460w", "/en/static/9e11eb0c071d901b027f02b86d85eed3/c0255/QVRPro2_03b.png 920w", "/en/static/9e11eb0c071d901b027f02b86d85eed3/95e27/QVRPro2_03b.png 1199w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9e11eb0c071d901b027f02b86d85eed3/c0255/QVRPro2_03b.png",
              "alt": "QNAP QVR Pro 2.1 Surveillance Software",
              "title": "QNAP QVR Pro 2.1 Surveillance Software",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Select INSTAR as `}<strong parentName="p">{`Brand`}</strong>{` and your camera `}<strong parentName="p">{`Model`}</strong>{`. In addition you now have to specify your camera's `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Network/IP_Configuration/"
      }}>{`IP Address, HTTP Port and RTSP Port`}</a>{`. Test your settings and click next.`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Note`}</strong>{` that the INSTAR profile allows you to use the (HD, Full HD and WQHD) camera's PIR sensor as an alarm trigger for the QVR Motion Detection.`}</p>
    </blockquote>
    <h4 {...{
      "id": "generic-rtsp",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#generic-rtsp",
        "aria-label": "generic rtsp permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Generic RTSP`}</h4>
    <p>{`By stopping the ONVIF network and clicking on `}<strong parentName="p">{`Next`}</strong>{` you now have the option to add your camera manually:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a9dd0179c9622bd49114566751960724/690c6/QVRPro2_03c.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "47.82608695652174%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABHklEQVQoz53RXU+DMBSA4f7/P+SFmRswwCmod0vMrphubLTlnH5RWhgG5hITzYw+6UX7JidNWrLZbCil3nvnXPuT791a67uOlyXZjd4ZY1JKdSHPhJCX3WebilJSGz0OF0VRbLe73Z5ShkIAIACiEIjTEgIQx7OQ5wKAZXk4UsrLA7mdp0GSzcOHYJktlo934SpK8iDOwjgPkzxKn+L752T1EsZZsMyiNI+SfLZIb2bh63pNQEilVV2DaRohpFLati0iGmO0McY0znnvOwBUSiutrbVN00otj/s3Mvzd6XQahgEp+/8wUEYYY/yLqp4e6Kq2bcevqirCOT+nGgCgrqQxzS+stc45XlECANbavu+7Se+vAetqqQDGi6rD8QOOyzTfHSUWJAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a9dd0179c9622bd49114566751960724/e4706/QVRPro2_03c.avif 230w", "/en/static/a9dd0179c9622bd49114566751960724/d1af7/QVRPro2_03c.avif 460w", "/en/static/a9dd0179c9622bd49114566751960724/7f308/QVRPro2_03c.avif 920w", "/en/static/a9dd0179c9622bd49114566751960724/53eb0/QVRPro2_03c.avif 1201w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a9dd0179c9622bd49114566751960724/a0b58/QVRPro2_03c.webp 230w", "/en/static/a9dd0179c9622bd49114566751960724/bc10c/QVRPro2_03c.webp 460w", "/en/static/a9dd0179c9622bd49114566751960724/966d8/QVRPro2_03c.webp 920w", "/en/static/a9dd0179c9622bd49114566751960724/3718f/QVRPro2_03c.webp 1201w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a9dd0179c9622bd49114566751960724/81c8e/QVRPro2_03c.png 230w", "/en/static/a9dd0179c9622bd49114566751960724/08a84/QVRPro2_03c.png 460w", "/en/static/a9dd0179c9622bd49114566751960724/c0255/QVRPro2_03c.png 920w", "/en/static/a9dd0179c9622bd49114566751960724/690c6/QVRPro2_03c.png 1201w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a9dd0179c9622bd49114566751960724/c0255/QVRPro2_03c.png",
              "alt": "QNAP QVR Pro 2.1 Surveillance Software",
              "title": "QNAP QVR Pro 2.1 Surveillance Software",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Select `}<em parentName="p">{`Generic`}</em>{` as `}<strong parentName="p">{`Brand`}</strong>{` and `}<em parentName="p">{`RTSP Model`}</em>{` as `}<strong parentName="p">{`Model`}</strong>{`. Add your camera's `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Network/IP_Configuration/"
      }}>{`IP Address, HTTP Port and RTSP Port`}</a>{`. In addition you now have to specify your camera's `}<a parentName="p" {...{
        "href": "/en/Outdoor_Cameras/IN-9020_HD/Video_Streaming/"
      }}>{`RTSP Streaming URL`}</a>{`. Test your settings and click next.`}</p>
    <p><strong parentName="p">{`Default Streaming URLs`}</strong>{` (high, middle and low resolution):`}</p>
    <ul>
      <li parentName="ul">{`INSTAR HD & Full HD Cameras: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`/11`}</code>{`, `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`/12`}</code>{` and `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`/13`}</code></li>
      <li parentName="ul">{`INSTAR WQHD Cameras: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`/livestream/11`}</code>{`, `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`/livestream/12`}</code>{` and `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`/livestream/13`}</code></li>
    </ul>
    <h3 {...{
      "id": "activating-the-video-recordings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#activating-the-video-recordings",
        "aria-label": "activating the video recordings permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Activating the Video Recordings`}</h3>
    <p>{`You will now be asked to set the video streaming and recording settings. Make sure to add the tick to `}<strong parentName="p">{`Enable Recording`}</strong>{` or you won´t be able to see your camera's video stream:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/cad7609b610da71750a68aa3122a4116/4d383/QVRPro2_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "47.82608695652174%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABIUlEQVQoz5WR3Y6CMBCFef+nMrsqaogINZus8VZB1LYzpQzT6rLBisule9KLr5M5mb9ov99fLhfvPT/knHtB4Be8uG3brus2mywqi14IUNfGGIOIxpjAYxgzADjHeS6i792uKEpA1Bo0gNaAaABQA6ghAoCA5pkAKKVqiDZZFk0m8+liPVuk0zj9nKfxKk/Sr1UiZnEfCS9eZctEPL995nqZbCcfs+hcVeXpZCkMxU3TKClLCZqcZ24fwbZtATDMTETWNvf7vW+7qqrDsajQOt+rrm15POyO57LxP/65ImZWSjGz9/5htrfbTYhtFAp69yceb3YQ0dAbMxF1XZcLEQ0G977CqfrK42P+y9xXvl6vROS9f8emiBUaJWVt63Wa/gLgkjK+qDUl+AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cad7609b610da71750a68aa3122a4116/e4706/QVRPro2_04.avif 230w", "/en/static/cad7609b610da71750a68aa3122a4116/d1af7/QVRPro2_04.avif 460w", "/en/static/cad7609b610da71750a68aa3122a4116/7f308/QVRPro2_04.avif 920w", "/en/static/cad7609b610da71750a68aa3122a4116/7842e/QVRPro2_04.avif 1195w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/cad7609b610da71750a68aa3122a4116/a0b58/QVRPro2_04.webp 230w", "/en/static/cad7609b610da71750a68aa3122a4116/bc10c/QVRPro2_04.webp 460w", "/en/static/cad7609b610da71750a68aa3122a4116/966d8/QVRPro2_04.webp 920w", "/en/static/cad7609b610da71750a68aa3122a4116/2ccb3/QVRPro2_04.webp 1195w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cad7609b610da71750a68aa3122a4116/81c8e/QVRPro2_04.png 230w", "/en/static/cad7609b610da71750a68aa3122a4116/08a84/QVRPro2_04.png 460w", "/en/static/cad7609b610da71750a68aa3122a4116/c0255/QVRPro2_04.png 920w", "/en/static/cad7609b610da71750a68aa3122a4116/4d383/QVRPro2_04.png 1195w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/cad7609b610da71750a68aa3122a4116/c0255/QVRPro2_04.png",
              "alt": "QNAP QVR Pro 2.1 Surveillance Software",
              "title": "QNAP QVR Pro 2.1 Surveillance Software",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click next and you will see an overview over all connected cameras:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3f44ff0a9dceb693377b873cbd505f08/29229/QVRPro2_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "51.30434782608696%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABsUlEQVQoz01Ry24TMRTNt5MihMSOHfADSKgs2NEFLVKrPJqoIam6TeaRSTJjj9/j8bV9wZkg9ViyzrV9rHPuHZGGHPYVpQSMic458N5ab60DGEj/ioDtfWc9gOssr5uRMaZp6tPxSCghlDDWSiWFlFJJKaVIK1VcJABAiCEhBu/9qG3b7S6jLSvKfVHsd1m+3Wbb7a4o92VZ7bIyL6ssL/Oi2mV5dTgR2hLa1g0RXIwa0n799uP9h4+z5Waxepk8ribz1cPsz8N0PVus58vn2WKzXL3MFpv5Yn0/WU8fN49Pz/fTp6yoRoh4c/NzPB5baxFjyuoCY871ATFZRMTUCuc6a7W2GDGZDwEARt77X7d3Xz5/IoQorYTkUklCOWNSSi2EUEpxzo0xhNDjkUqpOU8d6LouiW/vfr97e1WfTlqrhjDGRNPQuhacKymTWAihtaaMtS0b/rqIEfH6+/Wb8ZVSOsSgO2MtUGq0BsTQ92lAfd/HGJU2jBkPse+t9/5i+3A45GX5P0kECF0HAD7GNPjh3Tk5OOcQ0ft0lcRDjYhxGGAIMYZ4xpm/3i9kOPkn/gsW9C2p3hzduwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3f44ff0a9dceb693377b873cbd505f08/e4706/QVRPro2_05.avif 230w", "/en/static/3f44ff0a9dceb693377b873cbd505f08/d1af7/QVRPro2_05.avif 460w", "/en/static/3f44ff0a9dceb693377b873cbd505f08/7f308/QVRPro2_05.avif 920w", "/en/static/3f44ff0a9dceb693377b873cbd505f08/7e46f/QVRPro2_05.avif 1167w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3f44ff0a9dceb693377b873cbd505f08/a0b58/QVRPro2_05.webp 230w", "/en/static/3f44ff0a9dceb693377b873cbd505f08/bc10c/QVRPro2_05.webp 460w", "/en/static/3f44ff0a9dceb693377b873cbd505f08/966d8/QVRPro2_05.webp 920w", "/en/static/3f44ff0a9dceb693377b873cbd505f08/3eff0/QVRPro2_05.webp 1167w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3f44ff0a9dceb693377b873cbd505f08/81c8e/QVRPro2_05.png 230w", "/en/static/3f44ff0a9dceb693377b873cbd505f08/08a84/QVRPro2_05.png 460w", "/en/static/3f44ff0a9dceb693377b873cbd505f08/c0255/QVRPro2_05.png 920w", "/en/static/3f44ff0a9dceb693377b873cbd505f08/29229/QVRPro2_05.png 1167w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3f44ff0a9dceb693377b873cbd505f08/c0255/QVRPro2_05.png",
              "alt": "QNAP QVR Pro 2.1 Surveillance Software",
              "title": "QNAP QVR Pro 2.1 Surveillance Software",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "motion-detection",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#motion-detection",
        "aria-label": "motion detection permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Motion Detection`}</h3>
    <p>{`Close the `}<strong parentName="p">{`Camera Settings`}</strong>{` menu and open the `}<strong parentName="p">{`Motion Detektion`}</strong>{` menu:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/48dfda50dcede7ddc13e283049f8d245/a9965/QVRPro2_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "39.130434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7rAAAO6wFxzYGVAAABmUlEQVQY002QwW7TQBCG/X69AG1pG4NocumRSyUuXJFauNLCK3Bp1QpsE5MixYloqJMmKZAYUbfbJEiFZoKdeL327uwiO4AYfYfvH83h12ibpXsbxfX7K8svX+we2WXbMjdKxZXbt/TlpcLS4lrO6uKdoq6vF9ZKD4qF0sPMdf3uwoJGCLkejcIJ8JimOQmN5mSR/oksipK5T4OU0mkQDNymJpALkSIKKTFHqnxykf/tUf2b/CbwieZ9I1+8i/aZd9rttzq9VqfXPvNa3Uzc08/HJ+2G281ofqp97Dj1k2a753b6X/2r4PxSe22+f/T4yas90yhXjbdVw67tHVYP3tRNu2ZV6ta7ulF2zLJjHB0fVhqW7RiVD/uW03C74eWVNhiQnZ3nABBEMWUMuQggSjmfF/w1ARrN5l3/dhYKY6UU+ETr973t7a3hcPBzDGOYsJhef4cgZEJwzgVLRcKFlJLSGAAYYyiEYFRKOfaJdu5fPHu6NRyObkIazmacsfEPSBKe/QpxlmKUCKVUHDMAEIgKEeOpUnjjk997rZIs0vkeAQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/48dfda50dcede7ddc13e283049f8d245/e4706/QVRPro2_06.avif 230w", "/en/static/48dfda50dcede7ddc13e283049f8d245/d1af7/QVRPro2_06.avif 460w", "/en/static/48dfda50dcede7ddc13e283049f8d245/7f308/QVRPro2_06.avif 920w", "/en/static/48dfda50dcede7ddc13e283049f8d245/39686/QVRPro2_06.avif 1171w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/48dfda50dcede7ddc13e283049f8d245/a0b58/QVRPro2_06.webp 230w", "/en/static/48dfda50dcede7ddc13e283049f8d245/bc10c/QVRPro2_06.webp 460w", "/en/static/48dfda50dcede7ddc13e283049f8d245/966d8/QVRPro2_06.webp 920w", "/en/static/48dfda50dcede7ddc13e283049f8d245/37778/QVRPro2_06.webp 1171w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/48dfda50dcede7ddc13e283049f8d245/81c8e/QVRPro2_06.png 230w", "/en/static/48dfda50dcede7ddc13e283049f8d245/08a84/QVRPro2_06.png 460w", "/en/static/48dfda50dcede7ddc13e283049f8d245/c0255/QVRPro2_06.png 920w", "/en/static/48dfda50dcede7ddc13e283049f8d245/a9965/QVRPro2_06.png 1171w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/48dfda50dcede7ddc13e283049f8d245/c0255/QVRPro2_06.png",
              "alt": "QNAP QVR Pro 2.1 Surveillance Software",
              "title": "QNAP QVR Pro 2.1 Surveillance Software",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Here you can now configure the motion detection and select action rules that should be triggered when motion was detected.`}</p>
    <h4 {...{
      "id": "detection-settings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#detection-settings",
        "aria-label": "detection settings permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Detection Settings`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/92be5fe1ecd02587b13fc61b2dbd2dc9/cdef6/QVRPro2_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACgElEQVQoz1WSS08TcRTF55sYF7rQLQsTjTujwbUJaiBKYhsXRihaSFWEMiHAIHSgOp1pi0ZrYnj0NW03fAaLQDQo0HamneljprYU5vF/TMeUYqK/3MXdnNxz7z3Eos83T1HU7Oza2urPvb2tbHZne/tbNps7PFQVpVqp1KrVaqVSKZf/rbIsf/74iQgEAgzD0DSdTCY1TVdVta4qR6cYhmm12whhy7Ls/7EsKxaLETS95Hk5MfxsjA2uAACkar1QabSOdQCAaZoYY0mSCn8RilJeLO1+/1Gr1RKJBLG0tHy//8GNm7cZNoggrKp1RQMYQQihYRi2bW9ubgaDQZIkF32+MXLBNflmYnZhK/s1k8kQLBtwuVyDgw83NjYQhK3jk5YOTrVn4nQ6HYlEKIpyu0cdI6/uOFzzfm53ZzudzhChUOiRw9HbeysajUIIm81mo66qjWbTgMA8m+z3+0mS7Lt7z/l8vM85QrPvz8QcF/R6vUNDTxOJBEKoVquVylUNQAShruu2bUciEafTOT097fG8eDw62f/EM0MzB/v7qVSKCDBMOBzmWJZPJiGER81mo3VswM7WXdvRWHzY5WLZQCgc9n/4MkWH5uh3RVHgeZ5gOZbluGW/n+f5rm1dOwGmYQIAILQwltXGr/0DURBEUczlC5IkK4qiaVosHicY2vfWRy/OUfH1dWCajcZvRVVlpW4aRudboHM8hLoXhN0eIQQAiK6uE+NXrpLXrs/09MTd7rZt6x0MHbe7SbDabWxZnZScgjDuSDHCGKcohhi9cNF7+dLr8+dWBgbkUkkWhLIoVgRBFkRZECVBlMWiXCx1qiQJuUL+IJc/zAn5wtoU/QcpMTPW07TvxgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/92be5fe1ecd02587b13fc61b2dbd2dc9/e4706/QVRPro2_07.avif 230w", "/en/static/92be5fe1ecd02587b13fc61b2dbd2dc9/d1af7/QVRPro2_07.avif 460w", "/en/static/92be5fe1ecd02587b13fc61b2dbd2dc9/7f308/QVRPro2_07.avif 920w", "/en/static/92be5fe1ecd02587b13fc61b2dbd2dc9/63701/QVRPro2_07.avif 1163w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/92be5fe1ecd02587b13fc61b2dbd2dc9/a0b58/QVRPro2_07.webp 230w", "/en/static/92be5fe1ecd02587b13fc61b2dbd2dc9/bc10c/QVRPro2_07.webp 460w", "/en/static/92be5fe1ecd02587b13fc61b2dbd2dc9/966d8/QVRPro2_07.webp 920w", "/en/static/92be5fe1ecd02587b13fc61b2dbd2dc9/5231b/QVRPro2_07.webp 1163w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/92be5fe1ecd02587b13fc61b2dbd2dc9/81c8e/QVRPro2_07.png 230w", "/en/static/92be5fe1ecd02587b13fc61b2dbd2dc9/08a84/QVRPro2_07.png 460w", "/en/static/92be5fe1ecd02587b13fc61b2dbd2dc9/c0255/QVRPro2_07.png 920w", "/en/static/92be5fe1ecd02587b13fc61b2dbd2dc9/cdef6/QVRPro2_07.png 1163w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/92be5fe1ecd02587b13fc61b2dbd2dc9/c0255/QVRPro2_07.png",
              "alt": "QNAP QVR Pro 2.1 Surveillance Software",
              "title": "QNAP QVR Pro 2.1 Surveillance Software",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Select if you want the QVR Pro Software to use it's internal, software-based, motion detection, or if you want your camera to use it's own motion detection (including the PIR motion sensor - only available if you added your camera via the `}<a parentName="p" {...{
        "href": "#adding-a-camera"
      }}>{`INSTAR preset or the ONVIF T Profile`}</a>{`).`}</p>
    <p>{`If you use the QVR Pro motion detection you can set sensitivity levels and motion detection areas.`}</p>
    <h4 {...{
      "id": "rules",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#rules",
        "aria-label": "rules permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Rules`}</h4>
    <p>{`With the motion detection configured we can now add an If/Then rule set to specify what we want to happen when motion is detected:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e9e45e4d8b95415e1dcc943279c68128/d74fe/QVRPro2_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAAmElEQVQoz6WRyw6CQAxF5///URcaRAUSptN2Xq1jBoOyABPx5Ka5TXpWNefjYWgbN/QJXXSQ8JOIEN0csAFssDURYLzebHMxp+7uAFS1/EII0SOatuvtOCIiMxNWiKj2VeYDIhIRg+AsADOLSJ54leVclvdaSjHlDzZlVX1M7JFFhJl3yqqac/7+hXU5pRRCYGbvfc55S34C1HER/3DlX2UAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e9e45e4d8b95415e1dcc943279c68128/e4706/QVRPro2_08.avif 230w", "/en/static/e9e45e4d8b95415e1dcc943279c68128/d1af7/QVRPro2_08.avif 460w", "/en/static/e9e45e4d8b95415e1dcc943279c68128/7f308/QVRPro2_08.avif 920w", "/en/static/e9e45e4d8b95415e1dcc943279c68128/db27b/QVRPro2_08.avif 1164w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e9e45e4d8b95415e1dcc943279c68128/a0b58/QVRPro2_08.webp 230w", "/en/static/e9e45e4d8b95415e1dcc943279c68128/bc10c/QVRPro2_08.webp 460w", "/en/static/e9e45e4d8b95415e1dcc943279c68128/966d8/QVRPro2_08.webp 920w", "/en/static/e9e45e4d8b95415e1dcc943279c68128/4b3db/QVRPro2_08.webp 1164w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e9e45e4d8b95415e1dcc943279c68128/81c8e/QVRPro2_08.png 230w", "/en/static/e9e45e4d8b95415e1dcc943279c68128/08a84/QVRPro2_08.png 460w", "/en/static/e9e45e4d8b95415e1dcc943279c68128/c0255/QVRPro2_08.png 920w", "/en/static/e9e45e4d8b95415e1dcc943279c68128/d74fe/QVRPro2_08.png 1164w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e9e45e4d8b95415e1dcc943279c68128/c0255/QVRPro2_08.png",
              "alt": "QNAP QVR Pro 2.1 Surveillance Software",
              "title": "QNAP QVR Pro 2.1 Surveillance Software",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The first step is to name your rule, activate it and set a time schedule when it should be applied:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c1ddee7b85ac059455307ea900be5ca1/511f0/QVRPro2_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.65217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABF0lEQVQoz3WO207DMAxA+/+/hISE2AP/QEEd7QqjobGdW20XipJd1E3b0YkT+SKn6j/797e6axprhkDoETwV8ZYArkjWfn20VbPdtm03WvszjleOtzJH7GiMqQAAEVMhFvIVQlxxLoWSzzEEEakQkYi898YYRPQnXOHwDiGck+cSM+fNzjlV3e/3xhgAsNYys6qKiN4nbwaAYRhSmlSVWS7lVTw+1uRhYwZCRAQixPwRIkJHzuVDKjLPOmtW5HqYvs2IPqILFt0IBOTJxbXoQmmIaeLLYecxTuDjQZtjyt1r6RhjSryi+g1+EV5EFtWiLLMud/g7MReqR4sP6J7itGHdsD6zvqSp6/vdLXK67+u2e63rpmn+AZb6cvCXiQkCAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c1ddee7b85ac059455307ea900be5ca1/e4706/QVRPro2_09.avif 230w", "/en/static/c1ddee7b85ac059455307ea900be5ca1/d1af7/QVRPro2_09.avif 460w", "/en/static/c1ddee7b85ac059455307ea900be5ca1/7f308/QVRPro2_09.avif 920w", "/en/static/c1ddee7b85ac059455307ea900be5ca1/753ad/QVRPro2_09.avif 1172w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c1ddee7b85ac059455307ea900be5ca1/a0b58/QVRPro2_09.webp 230w", "/en/static/c1ddee7b85ac059455307ea900be5ca1/bc10c/QVRPro2_09.webp 460w", "/en/static/c1ddee7b85ac059455307ea900be5ca1/966d8/QVRPro2_09.webp 920w", "/en/static/c1ddee7b85ac059455307ea900be5ca1/40d03/QVRPro2_09.webp 1172w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c1ddee7b85ac059455307ea900be5ca1/81c8e/QVRPro2_09.png 230w", "/en/static/c1ddee7b85ac059455307ea900be5ca1/08a84/QVRPro2_09.png 460w", "/en/static/c1ddee7b85ac059455307ea900be5ca1/c0255/QVRPro2_09.png 920w", "/en/static/c1ddee7b85ac059455307ea900be5ca1/511f0/QVRPro2_09.png 1172w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c1ddee7b85ac059455307ea900be5ca1/c0255/QVRPro2_09.png",
              "alt": "QNAP QVR Pro 2.1 Surveillance Software",
              "title": "QNAP QVR Pro 2.1 Surveillance Software",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The event we want to react to is of type "Motion Detection" from a specific camera:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/dc9289c398934f2d1d1e591f095d48f5/cdef6/QVRPro2_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.21739130434783%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAB4ElEQVQoz2XQW2/TMBQH8HxjhEaZOokPEKVRLk4WUSYojNFOm8TjqJqqT+wD9PKwwcOGmjXOslycOE5znASSbgyJn87DseW/fGzBczd3v25Dzy1oUubZroBRGgd5Gpc54yx7rKwpaIrmaZp4nvD+4+DDYPD55OTLcDgcjUanp5+Oj79fXq4d5+bmNqWUZhnNdljTtztVXV9dXwuyLIuiKEmSLMuqqmqaJorit4uLKIpc13U3G0JIQgiJHxFCMMaU0sViISDDUBRFVRVNa5IIoV6vNx6PAaAsy5RlQRgyxvgTAKCUcs6Xy6UgSr29Tnev0+3sH7x6fbDfffPiZefs/GsQBBhjP3jwPOzf3/utJEnCMHQcx/f95mbDMHXd0HWkI6TrCBmmJPUmExtj/PPHFXbdOxxgPwhbzbsZy/OcUjqfzwXLsvr/ODp6ZxrGbDYDzpM4oCn1k5Juq6p+VrWrZuxd+O2Tfr+PEJpOp38P/e85jBCSZVlRFG0HGbKi2PakrmsADpwDLwE45yXwsgAOwLcF1HW9Wi0F0zTb31bN1uGhpaiqbdshYWs3dHDkeNHGj9du5D2QiNAwTv0gLoCvVqsmrGlaG2tYlqW24bKq8sZ2p2mKggPQNE0Skuf5n7F/AzQ4G9IJnV/6AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dc9289c398934f2d1d1e591f095d48f5/e4706/QVRPro2_10.avif 230w", "/en/static/dc9289c398934f2d1d1e591f095d48f5/d1af7/QVRPro2_10.avif 460w", "/en/static/dc9289c398934f2d1d1e591f095d48f5/7f308/QVRPro2_10.avif 920w", "/en/static/dc9289c398934f2d1d1e591f095d48f5/63701/QVRPro2_10.avif 1163w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/dc9289c398934f2d1d1e591f095d48f5/a0b58/QVRPro2_10.webp 230w", "/en/static/dc9289c398934f2d1d1e591f095d48f5/bc10c/QVRPro2_10.webp 460w", "/en/static/dc9289c398934f2d1d1e591f095d48f5/966d8/QVRPro2_10.webp 920w", "/en/static/dc9289c398934f2d1d1e591f095d48f5/5231b/QVRPro2_10.webp 1163w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dc9289c398934f2d1d1e591f095d48f5/81c8e/QVRPro2_10.png 230w", "/en/static/dc9289c398934f2d1d1e591f095d48f5/08a84/QVRPro2_10.png 460w", "/en/static/dc9289c398934f2d1d1e591f095d48f5/c0255/QVRPro2_10.png 920w", "/en/static/dc9289c398934f2d1d1e591f095d48f5/cdef6/QVRPro2_10.png 1163w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/dc9289c398934f2d1d1e591f095d48f5/c0255/QVRPro2_10.png",
              "alt": "QNAP QVR Pro 2.1 Surveillance Software",
              "title": "QNAP QVR Pro 2.1 Surveillance Software",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`When this event occurs we want to trigger an action - e.g. an Email or Push Notification or sending a command to another camera to move to one of it's preset positions:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f68e3254d09c37fedbff81b46dda2c1b/84bf8/QVRPro2_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABp0lEQVQoz1XRXW+bMBQGYP7xtIu0lbpdpmRrbpJiAvtL2+6iYdZ1a7cSg/lwHPxtiCdAreijV5YvfGQfH6+qK5Q9NyU2UvSd7a15E6N7M2xE2+IclXk+BSP09OvBq6rq5/09IUcuBGOMcc74YFiFEEK2jNmuSyAMANhF0ZQoina7nUcIQQjhoqCUCsEZY6fTqR0RQjDGR0I6a9M0DYK7cBe+AgB4Qoiu67iQuKwopW3bGmP6kVKqbVullHMuSZL1er3ZbLbbuylBEHhN0yB0wFXz++kvLgrOeV3XZMQ5l1KOq/j67fu794uLqw+Ly+uLq4+Ly2vf/zwUpyl8fD48PP7Lc2S7rn4x/sDQOGMsSX7EcfRlZuiZc37Inv9kCGaH7EhqrY+nExtNnVNKtdYQwjAM45mh2FrrnKNMZnnZWWusNcacZ/q+d86laQoAiGbCMJxuzlBeoLxomkZKKYQwL7TWSqnz+Qwh3G634C1PKYUxppQyxsqylFLqmaneOQchBADEcTxNeOJpre341Nejc9PYlVL7/X61Wvm3t8vVJ//mZrlc+r7/H8DcK14MTNSZAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f68e3254d09c37fedbff81b46dda2c1b/e4706/QVRPro2_11.avif 230w", "/en/static/f68e3254d09c37fedbff81b46dda2c1b/d1af7/QVRPro2_11.avif 460w", "/en/static/f68e3254d09c37fedbff81b46dda2c1b/7f308/QVRPro2_11.avif 920w", "/en/static/f68e3254d09c37fedbff81b46dda2c1b/c0f7c/QVRPro2_11.avif 1162w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f68e3254d09c37fedbff81b46dda2c1b/a0b58/QVRPro2_11.webp 230w", "/en/static/f68e3254d09c37fedbff81b46dda2c1b/bc10c/QVRPro2_11.webp 460w", "/en/static/f68e3254d09c37fedbff81b46dda2c1b/966d8/QVRPro2_11.webp 920w", "/en/static/f68e3254d09c37fedbff81b46dda2c1b/4f5bc/QVRPro2_11.webp 1162w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f68e3254d09c37fedbff81b46dda2c1b/81c8e/QVRPro2_11.png 230w", "/en/static/f68e3254d09c37fedbff81b46dda2c1b/08a84/QVRPro2_11.png 460w", "/en/static/f68e3254d09c37fedbff81b46dda2c1b/c0255/QVRPro2_11.png 920w", "/en/static/f68e3254d09c37fedbff81b46dda2c1b/84bf8/QVRPro2_11.png 1162w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f68e3254d09c37fedbff81b46dda2c1b/c0255/QVRPro2_11.png",
              "alt": "QNAP QVR Pro 2.1 Surveillance Software",
              "title": "QNAP QVR Pro 2.1 Surveillance Software",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Once everything is set up click on `}<strong parentName="p">{`Next`}</strong>{` to save the configuration:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4c1f30517869a790255682f9a117e1c8/d74fe/QVRPro2_12.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABZklEQVQoz4WRW3PaMBBG9f9/UyehlECG0KQpdCgBJsRYvsiyLAvji3YlZ4QID7lMzpyHT9odXcmehtvNhgaBFPmhlN8oz5ZFkYSUBMGeUiqKIhciF2cugef5Rz2cc3I8HjnnQuRKqbZtm7puGm/T1DUAWGucxoMGnYgIAKRQah9SSqMoTkIaxXHCWMYyzjIuhIhYnjKXs4ynLBOiKGQpSyWlOtY1oVFyNbwdjKaDm9lwPPt5MxvfPkymj6PJ/d/F6nH+/8dgcv1rejW6G54bfo+nfwajuyCMCYC21vZ9fzmZMcYNjQHQiNifysa19JcqIiAAAYD+Cy6tn+LurLXGtwfQDkBj7AkAQESf/ZxfzgetNTlUVRTHKWOlUlLKvDxUTdt1XfvON7rWVT3EAnRVBW3bW3vS9OYT/dbGWnT/5TDGkHkUz5538yheJIn3X5K+c5Gk6zDcv+yennfLzfZptVoul+v1+hW98WtrstMG7QAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4c1f30517869a790255682f9a117e1c8/e4706/QVRPro2_12.avif 230w", "/en/static/4c1f30517869a790255682f9a117e1c8/d1af7/QVRPro2_12.avif 460w", "/en/static/4c1f30517869a790255682f9a117e1c8/7f308/QVRPro2_12.avif 920w", "/en/static/4c1f30517869a790255682f9a117e1c8/db27b/QVRPro2_12.avif 1164w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4c1f30517869a790255682f9a117e1c8/a0b58/QVRPro2_12.webp 230w", "/en/static/4c1f30517869a790255682f9a117e1c8/bc10c/QVRPro2_12.webp 460w", "/en/static/4c1f30517869a790255682f9a117e1c8/966d8/QVRPro2_12.webp 920w", "/en/static/4c1f30517869a790255682f9a117e1c8/4b3db/QVRPro2_12.webp 1164w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4c1f30517869a790255682f9a117e1c8/81c8e/QVRPro2_12.png 230w", "/en/static/4c1f30517869a790255682f9a117e1c8/08a84/QVRPro2_12.png 460w", "/en/static/4c1f30517869a790255682f9a117e1c8/c0255/QVRPro2_12.png 920w", "/en/static/4c1f30517869a790255682f9a117e1c8/d74fe/QVRPro2_12.png 1164w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4c1f30517869a790255682f9a117e1c8/c0255/QVRPro2_12.png",
              "alt": "QNAP QVR Pro 2.1 Surveillance Software",
              "title": "QNAP QVR Pro 2.1 Surveillance Software",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The `}<strong parentName="p">{`Rule`}</strong>{` specified above waits for a motion detection event from `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`camera 1`}</code>{`. When this event occurs it will send a command to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`camera 2`}</code>{` to move to a specified preset position.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      